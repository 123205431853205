$primaryColor: #004874;
$white: #ffffff;
$black: #000000;

@font-face {
    font-family: "Sparkasse";
    src: url("./font/Sparkasse_web_Rg.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Sparkasse";
    src: url("./font/Sparkasse_web_Bd.woff") format("woff");
    font-weight: bold;
    font-style: normal;
}

brain-catalog-banner-aoh,
.autocomplete-search-wrapper,
#client_name_tooltip,
#legal_entity_tooltip,
brain-global-catalog-filter-tabs,
.content__left .content__info a,
.article deloitteai-benefits,
.timeline__section .btn.btn-primary,
.section.additional-info,
#plan_q_1_measurable,
#plan_q_1_both,
#skus .btn-bar,
#plans .btn-bar,
deloitteai--onboarding-page deloitteai-video-player asset-owner--packages deloitteai-plan-card .btn.btn-primary,
.asset-owner-body .icon-hint,
deloitteai--onboarding-page .deloitteai__banner--inner .content__logo,
asset-owner--barrier-questions-main .counter,
asset-owner deloitteai-product-card .card__body--details, .onboarding-card .in-use {
    display: none !important;
}

.helaba-theme {
    font-family: 'Sparkasse', Helvetica, Arial, Sans-serif !important;

    .content__body--header h3, .content__body--content p {
        font-family: 'Sparkasse', Helvetica, Arial, Sans-serif !important;
    }

    aihub-loader {
        .loader-overlay .loader:before {
            background-color: $primaryColor !important;
        }
    }

    aihub-login-page {
        background-color: transparent;
    }

    deloitteai--onboarding-page {
        .timeline__section--image img {
            max-height: 200px;
        }

        .deloitteai__banner--inner {
            flex-direction: column !important;

            .content__body {
                flex: 0 0 12rem !important;
            }
        }
    }

    .navbar {
        box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
        background: $white;

        .nav-item:hover {
            background: transparent;
        }

        .header-logo {
            height: 25px !important;
            width: 100% !important;
            margin-left: 25px;
        }

        li a {
            color: $black !important;
        }
    }

    .static-nav-item, app-asset-publish-dashboard deloitteai-page-banner {
        color: $black !important;
    }

    .shared-header .mat-menu-trigger.active {
        background: transparent !important;
    }

    .bg-black,
    .catalogue__header,
    .brain-asset-detail .header,
    app-asset-publish-dashboard deloitteai-page-banner {
        background: $white !important;
    }

    .autocomplete-search-wrapper {
        border: 1px solid $primaryColor !important;
    }

    brain-global-header {
        .tile {
            box-shadow: none;
        }

        .asset-container {
            max-height: 350px;
            padding: 20px 5% !important;
        }

        .catalog_image img {
            max-height: 11rem !important;
            min-height: 11rem;
        }
    }

    .catalogue__header--title,
    .catalogue__header--subtitle,
    .brain-asset-detail .header-title,
    .brain-asset-detail .header-subtitle,
    .brain-asset-detail .header aihub-breadcrumb .list.breadcrumb-list .breadcrumb-list--item a.router-link-active {
        color: $black !important;

        p>img {
            display: none;
        }
    }

    .catalogue__header {
        padding-bottom: 0px !important;
    }

    .border-green {
        border-bottom: 1px solid #c7cacc;
    }

    .deloitteai--header__list .counter {
        background-color: transparent !important;
        border: 1px solid $black !important;
    }

    .btn.btn-primary,
    aihub-login-page .input-container button {
        color: $white !important;
        background-color: $primaryColor !important;

        &:disabled {
            opacity: 0.5 !important;
        }
    }

    asset-owner--menu li .label:before,
    .deloitteai__header--usermenu .user,
    .aihub__form input[type=checkbox]:checked+span:before,
    .entitlement_count,
    .user_count {
        color: $white !important;
        background-color: $primaryColor !important;
    }


    .aihub-tooltip mat-icon,
    .badge.success,
    .btn.btn-secondary,
    brain-global-catalog-page a,
    deloitteai-contact-banner a,
    .deloitteai--header__list .counter,
    .available-items .mat-chip,
    .text-primary,
    a,
    .breadcrumb-list .breadcrumb-list--item {
        color: $primaryColor !important;
    }

    footer {
        background-color: $white;
        border-top: 1px solid #ccc;

        a {
            font-weight: normal;
        }
    }

    .deloitteai__header--usermenu ul {
        background: $white;
    }

    .hamburger-logo {
        width: 30px !important;
        margin-right: 2rem;
        height: 20px !important;
    }

    .available-items .mat-chip,
    .brain-asset-detail .header .categories-item.active,
    .aihub__form input[type=checkbox]+span:before,
    .aihub__form input[type=radio]+span:before,
    .aihub__form input[type=number]:focus,
    .aihub__form input[type=text]:focus {
        border-color: $primaryColor !important;
    }

    .aihub__form input[type=text]:focus {
        box-shadow: 0 1px 1px $primaryColor;
    }

    .aihub__form input[type=checkbox]+span:after {
        background-color: transparent !important;
    }

    brain-asset-detail-markdown {
        margin-bottom: 80px;
    }

    .deloitteai__banner--inner .content__left {
        align-items: center;
    }

    .brain-asset-detail .header .categories-item {
        text-transform: capitalize;
    }

    .stepper__segment.active {
        stroke: $primaryColor !important;
    }
}